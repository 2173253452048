var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a;
import { analytics } from '@sezane/front-components';
import Cookies from 'js-cookie';
import { isWebview } from './utils';
import config from './config';
// eslint-disable-next-line import/no-cycle
import { getGlobalEData } from './components/globalE';
import environment from './env';
// eslint-disable-next-line import/no-mutable-exports
var context;
var site;
var productsInPage = [];
var subscriberKey = new URLSearchParams(window.location.search).get('sez_subscriberkey') || ((_a = window.userData) === null || _a === void 0 ? void 0 : _a.salesforceId);
var setContext = function (appContext) {
    context = appContext;
    site = "".concat(context.site.code.toLowerCase(), "_").concat(context.currencyLabel);
};
var formatPrice = function (price) { return (price ? (price / 100).toFixed(2) : undefined); };
// Nav history
var navPath = (window.sessionStorage ? JSON.parse(window.sessionStorage.getItem('navPath')) : []) || [];
var addNavPath = function () {
    navPath = __spreadArray(__spreadArray([], navPath.slice(-1), true), [window.document.title], false);
    if (window.sessionStorage) {
        window.sessionStorage.setItem('navPath', JSON.stringify(__spreadArray(__spreadArray([], navPath.slice(-1), true), [window.document.title], false)));
    }
};
addNavPath();
var segmentConfig = {
    writeKey: config.segmentKey,
    cdnURL: config.segmentCdn,
};
var getContentType = function () {
    return window.contentType || document.title;
};
// Product in page
var addProductInPage = function (product) {
    productsInPage.push(product);
};
var getCurrentSizeUUID = function (items) {
    var selectedSize = window.location.hash.replace('#size-', '');
    var currentSize = items.find(function (item) { return item.size === selectedSize; });
    return (currentSize === null || currentSize === void 0 ? void 0 : currentSize.uuid) || items[0].uuid;
};
var getEmail = function () { return __awaiter(void 0, void 0, void 0, function () {
    var _a;
    var _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (!((_b = window.userData) === null || _b === void 0 ? void 0 : _b.email)) return [3 /*break*/, 2];
                return [4 /*yield*/, analytics.getSha256(window.userData.email)];
            case 1:
                _a = _c.sent();
                return [3 /*break*/, 3];
            case 2:
                _a = undefined;
                _c.label = 3;
            case 3: return [2 /*return*/, _a];
        }
    });
}); };
// Events
var productsSearched = function (searchTerm, countResults) { return __awaiter(void 0, void 0, void 0, function () {
    var instance, globalEData, _a, _b, _c, _d;
    var _e;
    return __generator(this, function (_f) {
        switch (_f.label) {
            case 0: return [4 /*yield*/, analytics.getInstance(segmentConfig)];
            case 1:
                instance = _f.sent();
                globalEData = getGlobalEData();
                _b = (_a = instance).track;
                _c = ['Products Searched'];
                _e = {
                    event_id: analytics.getUniqueEventId('productsSearched'),
                    query: searchTerm,
                    'result-nb': countResults,
                    country: globalEData === null || globalEData === void 0 ? void 0 : globalEData.countryISO,
                    site: site,
                    siteBrand: context.brandCode,
                    siteCurrency: context.currencyCode,
                    langue: context.locale,
                    environnement: environment,
                    platform: 'web',
                    navPath: navPath.join('||'),
                    isWebview: isWebview()
                };
                _d = 'adresse-email';
                return [4 /*yield*/, getEmail()];
            case 2:
                _b.apply(_a, _c.concat([(_e[_d] = _f.sent(),
                        _e.fbp = Cookies.get('_fbp'),
                        _e.fbc = Cookies.get('_fbc'),
                        _e)]));
                return [2 /*return*/];
        }
    });
}); };
var productListViewed = function () {
    var args_1 = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args_1[_i] = arguments[_i];
    }
    return __awaiter(void 0, __spreadArray([], args_1, true), void 0, function (eventType) {
        var instance, products, globalEData;
        if (eventType === void 0) { eventType = 'Product page'; }
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, analytics.getInstance(segmentConfig)];
                case 1:
                    instance = _a.sent();
                    products = productsInPage.slice(0, 25);
                    globalEData = getGlobalEData();
                    instance.track('Product List Viewed', {
                        event_id: analytics.getUniqueEventId('productListViewed'),
                        'type-contenu': 'Liste produit',
                        'page-name': document.title,
                        list_id: document.title,
                        category: document.title,
                        country: globalEData === null || globalEData === void 0 ? void 0 : globalEData.countryISO,
                        content_category: products.map(function (product) { return product.productCategory; }),
                        content_ids: products.map(function (product) { return product.sizeUUID; }),
                        content_name: products.map(function (product) { return product.productName; }),
                        content_type: 'product_group',
                        site: site,
                        siteBrand: context.brandCode,
                        siteCurrency: context.currencyCode,
                        currency: context.currencyCode,
                        langue: context.locale,
                        environnement: environment,
                        platform: 'web',
                        navPath: navPath.join('||'),
                        'event-type': eventType,
                        products: products.map(function (product, index) { return ({
                            product_id: product.sizeUUID,
                            sku: product.productEAN,
                            name: product.productName,
                            brand: product.productBrand,
                            price: formatPrice(product.productPrice),
                            position: index + 1,
                            category: product.productCategory,
                            productUUID: product.productUUID,
                            productStatus: product.productStatus,
                            url: product.productURL,
                            product_code: product.productID,
                            fs_color: product.fsColor,
                            color: product.color,
                            collection: product.collection,
                        }); }),
                        isWebview: isWebview(),
                        fbp: Cookies.get('_fbp'),
                        fbc: Cookies.get('_fbc'),
                    });
                    return [2 /*return*/];
            }
        });
    });
};
var productViewed = function (_a) { return __awaiter(void 0, void 0, void 0, function () {
    var instance, globalEData, _b, _c, _d, _e;
    var _f;
    var sizesSoldOut = _a.sizesSoldOut, product = __rest(_a, ["sizesSoldOut"]);
    return __generator(this, function (_g) {
        switch (_g.label) {
            case 0: return [4 /*yield*/, analytics.getInstance(segmentConfig)];
            case 1:
                instance = _g.sent();
                globalEData = getGlobalEData();
                _c = (_b = instance).track;
                _d = ['Product Viewed'];
                _f = {
                    event_id: analytics.getUniqueEventId('productViewed'),
                    'type-contenu': 'Page Produit',
                    'page-name': document.title,
                    product_id: getCurrentSizeUUID(product.items),
                    sku: product.productEAN,
                    category: product.productCategory,
                    name: product.productName,
                    brand: product.productBrand.toLowerCase(),
                    country: globalEData === null || globalEData === void 0 ? void 0 : globalEData.countryISO,
                    variant: product.productVariant,
                    price: formatPrice(product.productPrice),
                    currency: context.currencyCode,
                    value: formatPrice(product.productPrice),
                    productStatus: product.productStatus,
                    productUUID: product.productUUID,
                    product_code: product.productID,
                    content_category: product.productCategory,
                    content_ids: product.sizeUUID,
                    content_name: product.productName,
                    content_type: 'product',
                    site: site,
                    siteBrand: context.brandCode,
                    siteCurrency: context.currencyCode,
                    langue: context.locale,
                    environnement: environment,
                    platform: 'web',
                    navPath: navPath.join('||'),
                    'event-type': 'Product page',
                    label: product.productName,
                    url: product.productURL,
                    isWebview: isWebview(),
                    subscriber_key: subscriberKey,
                    fs_color: product.fsColor,
                    color: product.color,
                    collection: product.collection
                };
                _e = 'adresse-email';
                return [4 /*yield*/, getEmail()];
            case 2:
                _c.apply(_b, _d.concat([(_f[_e] = _g.sent(),
                        _f.fbp = Cookies.get('_fbp'),
                        _f.fbc = Cookies.get('_fbc'),
                        _f)]));
                if (sizesSoldOut.length > 0) {
                    instance.track('Size sold out', {
                        event_id: analytics.getUniqueEventId('sizeSoldOut'),
                        'type-contenu': 'Page Produit',
                        'page-name': document.title,
                        product_id: product.sizeUUID,
                        category: product.productCategory,
                        name: product.productName,
                        variant: product.productVariant,
                        price: formatPrice(product.productPrice),
                        site: site,
                        country: globalEData === null || globalEData === void 0 ? void 0 : globalEData.countryISO,
                        siteBrand: context.brandCode,
                        siteCurrency: context.currencyCode,
                        langue: context.locale,
                        environnement: environment,
                        platform: 'web',
                        label: product.productName,
                        value: formatPrice(product.productPrice),
                        size: sizesSoldOut.join(', '),
                        productUUID: product.productUUID,
                        sku: product.productEAN,
                        isWebview: isWebview(),
                        nonInteraction: 1,
                        fs_color: product.fsColor,
                        color: product.color,
                        collection: product.collection,
                    });
                }
                return [2 /*return*/];
        }
    });
}); };
var pageLoad = function (pageLoadData) { return __awaiter(void 0, void 0, void 0, function () {
    var instance, userId, email, salesforceId, breadcrumb, domain, globalEData, _a, _b, _c, _d, _e, _f, _g, _h, _j, hasRegistered, hasAuthenticated, _k, _l, _m, _o, _p, _q, _r, _s;
    var _t, _u, _v, _w;
    return __generator(this, function (_x) {
        switch (_x.label) {
            case 0: return [4 /*yield*/, analytics.getInstance(segmentConfig)];
            case 1:
                instance = _x.sent();
                userId = pageLoadData ? pageLoadData.userId : undefined;
                email = pageLoadData ? pageLoadData.email : undefined;
                salesforceId = pageLoadData && pageLoadData.salesforceId ? pageLoadData.salesforceId : undefined;
                breadcrumb = pageLoadData.breadcrumb;
                domain = window.location.hostname.replace(/(www-nocache|www)/, '');
                globalEData = getGlobalEData();
                _b = (_a = instance).page;
                _c = [document.title];
                _t = {
                    event_id: analytics.getUniqueEventId('pageLoad'),
                    breadcrumb: breadcrumb,
                    navPath: navPath.join('||'),
                    site: site,
                    country: globalEData === null || globalEData === void 0 ? void 0 : globalEData.countryISO,
                    siteBrand: context.brandCode,
                    siteCurrency: context.currencyCode,
                    langue: context.locale,
                    environnement: environment,
                    platform: 'web',
                    'statut-connexion': userId ? 'Logged' : 'Unlogged',
                    'type-contenu': getContentType(),
                    'user-id': userId,
                    isWebview: isWebview()
                };
                _d = 'adresse-email';
                if (!email) return [3 /*break*/, 3];
                return [4 /*yield*/, analytics.getSha256(email)];
            case 2:
                _e = _x.sent();
                return [3 /*break*/, 4];
            case 3:
                _e = undefined;
                _x.label = 4;
            case 4:
                _b.apply(_a, _c.concat([(_t[_d] = _e,
                        _t.subscriber_key = salesforceId || subscriberKey,
                        _t.fbp = Cookies.get('_fbp'),
                        _t.fbc = Cookies.get('_fbc'),
                        _t)]));
                if (!(navPath.length === 1)) return [3 /*break*/, 6];
                _g = (_f = instance).identify;
                _h = [userId];
                _u = {
                    event_id: analytics.getUniqueEventId('userIdentify')
                };
                _j = 'adresse-email';
                return [4 /*yield*/, analytics.getSha256(email)];
            case 5:
                _g.apply(_f, _h.concat([(_u[_j] = _x.sent(),
                        _u.country = globalEData === null || globalEData === void 0 ? void 0 : globalEData.countryISO,
                        _u.fbp = Cookies.get('_fbp'),
                        _u.fbc = Cookies.get('_fbc'),
                        _u['pint-epik'] = Cookies.get('_epik'),
                        _u.breadcrumb = breadcrumb,
                        _u['internal-id'] = userId || new URLSearchParams(document.location.search).get('client_id'),
                        _u.isWebview = isWebview(),
                        _u)]));
                _x.label = 6;
            case 6:
                hasRegistered = Cookies.get('accountCreated');
                hasAuthenticated = Cookies.get('authenticated');
                if (!hasRegistered) return [3 /*break*/, 8];
                instance.track('Signed Up', {
                    event_id: analytics.getUniqueEventId('signedUp'),
                    site: site,
                    country: globalEData === null || globalEData === void 0 ? void 0 : globalEData.countryISO,
                    siteBrand: context.brandCode,
                    siteCurrency: context.currencyCode,
                    langue: context.locale,
                    environnement: environment,
                    platform: 'web',
                    navPath: navPath.join('||'),
                    isWebview: isWebview(),
                });
                _l = (_k = instance).identify;
                _m = [userId];
                _v = {
                    event_id: analytics.getUniqueEventId('userIdentify')
                };
                _o = 'adresse-email';
                return [4 /*yield*/, analytics.getSha256(email)];
            case 7:
                _l.apply(_k, _m.concat([(_v[_o] = _x.sent(),
                        _v)]));
                Cookies.remove('accountCreated', { domain: domain, path: '/' });
                _x.label = 8;
            case 8:
                if (!hasAuthenticated) return [3 /*break*/, 10];
                instance.track('Login', {
                    event_id: analytics.getUniqueEventId('login'),
                    site: site,
                    country: globalEData === null || globalEData === void 0 ? void 0 : globalEData.countryISO,
                    siteBrand: context.brandCode,
                    siteCurrency: context.currencyCode,
                    langue: context.locale,
                    environnement: environment,
                    platform: 'web',
                    navPath: navPath.join('||'),
                    isWebview: isWebview(),
                });
                _q = (_p = instance).identify;
                _r = [userId];
                _w = {
                    event_id: analytics.getUniqueEventId('userIdentify')
                };
                _s = 'adresse-email';
                return [4 /*yield*/, analytics.getSha256(email)];
            case 9:
                _q.apply(_p, _r.concat([(_w[_s] = _x.sent(),
                        _w)]));
                Cookies.remove('authenticated', { domain: domain, path: '/' });
                _x.label = 10;
            case 10: return [2 /*return*/];
        }
    });
}); };
var productCustomisationViewed = function (product) { return __awaiter(void 0, void 0, void 0, function () {
    var instance, globalEData;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, analytics.getInstance(segmentConfig)];
            case 1:
                instance = _a.sent();
                globalEData = getGlobalEData();
                instance.track('Product Personalization Viewed', {
                    event_id: analytics.getUniqueEventId('productPersonalizationViewed'),
                    product_id: product.sizeUUID,
                    product_code: product.productID,
                    productUUID: product.productUUID,
                    country: globalEData === null || globalEData === void 0 ? void 0 : globalEData.countryISO,
                    sku: product.productEAN,
                    name: product.productName,
                    price: formatPrice(product.productPrice),
                    label: product.productName,
                    value: formatPrice(product.productPrice),
                    category: product.productCategory,
                    site: site,
                    siteBrand: context.brandCode,
                    siteCurrency: context.currencyCode,
                    langue: context.locale,
                    environnement: environment,
                    platform: 'web',
                    isWebview: isWebview(),
                    nonInteraction: 1,
                    fs_color: product.fsColor,
                    color: product.color,
                    collection: product.collection,
                });
                return [2 /*return*/];
        }
    });
}); };
var productWithoutCustomisation = function (product) { return __awaiter(void 0, void 0, void 0, function () {
    var instance, globalEData;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, analytics.getInstance(segmentConfig)];
            case 1:
                instance = _a.sent();
                globalEData = getGlobalEData();
                instance.track('Without Product Personalization', {
                    event_id: analytics.getUniqueEventId('productWithoutPersonalization'),
                    product_id: product.sizeUUID,
                    product_code: product.productID,
                    productUUID: product.productUUID,
                    sku: product.productEAN,
                    country: globalEData === null || globalEData === void 0 ? void 0 : globalEData.countryISO,
                    name: product.productName,
                    price: formatPrice(product.productPrice),
                    label: product.productName,
                    value: formatPrice(product.productPrice),
                    category: product.productCategory,
                    site: site,
                    siteBrand: context.brandCode,
                    siteCurrency: context.currencyCode,
                    langue: context.locale,
                    environnement: environment,
                    platform: 'web',
                    isWebview: isWebview(),
                    fs_color: product.fsColor,
                    color: product.color,
                    collection: product.collection,
                });
                return [2 /*return*/];
        }
    });
}); };
var productWithCustomisation = function (product) { return __awaiter(void 0, void 0, void 0, function () {
    var instance, globalEData;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, analytics.getInstance(segmentConfig)];
            case 1:
                instance = _a.sent();
                globalEData = getGlobalEData();
                instance.track('Product Personalization Clicked', {
                    event_id: analytics.getUniqueEventId('productPersonalizationClicked'),
                    product_id: product.sizeUUID,
                    product_code: product.productID,
                    productUUID: product.productUUID,
                    sku: product.productEAN,
                    name: product.productName,
                    country: globalEData === null || globalEData === void 0 ? void 0 : globalEData.countryISO,
                    price: formatPrice(product.productPrice),
                    label: product.productName,
                    value: formatPrice(product.productPrice),
                    category: product.productCategory,
                    site: site,
                    siteBrand: context.brandCode,
                    siteCurrency: context.currencyCode,
                    langue: context.locale,
                    environnement: environment,
                    platform: 'web',
                    isWebview: isWebview(),
                    fs_color: product.fsColor,
                    color: product.color,
                    collection: product.collection,
                });
                return [2 /*return*/];
        }
    });
}); };
var productCustomisationClosed = function (product) { return __awaiter(void 0, void 0, void 0, function () {
    var instance, globalEData;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, analytics.getInstance(segmentConfig)];
            case 1:
                instance = _a.sent();
                globalEData = getGlobalEData();
                instance.track('Product Personalization Closed', {
                    event_id: analytics.getUniqueEventId('productPersonalizationClosed'),
                    product_id: product.sizeUUID,
                    product_code: product.productID,
                    productUUID: product.productUUID,
                    sku: product.productEAN,
                    name: product.productName,
                    price: formatPrice(product.productPrice),
                    label: product.productName,
                    value: formatPrice(product.productPrice),
                    category: product.productCategory,
                    country: globalEData === null || globalEData === void 0 ? void 0 : globalEData.countryISO,
                    site: site,
                    siteBrand: context.brandCode,
                    siteCurrency: context.currencyCode,
                    langue: context.locale,
                    environnement: environment,
                    platform: 'web',
                    isWebview: isWebview(),
                    fs_color: product.fsColor,
                    color: product.color,
                    collection: product.collection,
                });
                return [2 /*return*/];
        }
    });
}); };
var shopTheLook = function (product) { return __awaiter(void 0, void 0, void 0, function () {
    var instance, globalEData;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, analytics.getInstance(segmentConfig)];
            case 1:
                instance = _a.sent();
                globalEData = getGlobalEData();
                instance.track('Pop-in shop the look viewed', {
                    event_id: analytics.getUniqueEventId('popinShopTheLookViewed'),
                    product_id: product.sizeUUID,
                    product_code: product.productID,
                    productUUID: product.productUUID,
                    sku: product.productEAN,
                    name: product.productName,
                    price: formatPrice(product.productPrice),
                    value: formatPrice(product.productPrice),
                    category: product.productCategory,
                    country: globalEData === null || globalEData === void 0 ? void 0 : globalEData.countryISO,
                    site: site,
                    siteBrand: context.brandCode,
                    siteCurrency: context.currencyCode,
                    langue: context.locale,
                    environnement: environment,
                    platform: 'web',
                    isWebview: isWebview(),
                    fs_color: product.fsColor,
                    color: product.color,
                    collection: product.collection,
                });
                return [2 /*return*/];
        }
    });
}); };
var shopTheLookProductClicked = function (product) { return __awaiter(void 0, void 0, void 0, function () {
    var instance, globalEData;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, analytics.getInstance(segmentConfig)];
            case 1:
                instance = _a.sent();
                globalEData = getGlobalEData();
                instance.track('Pop-in shop the look clicked', {
                    event_id: analytics.getUniqueEventId('popinShopTheLookClicked'),
                    product_id: product.sizeUUID,
                    product_code: product.productID,
                    productUUID: product.productUUID,
                    sku: product.productEAN,
                    name: product.productName,
                    country: globalEData === null || globalEData === void 0 ? void 0 : globalEData.countryISO,
                    price: formatPrice(product.productPrice),
                    value: formatPrice(product.productPrice),
                    category: product.productCategory,
                    site: site,
                    siteBrand: context.brandCode,
                    siteCurrency: context.currencyCode,
                    langue: context.locale,
                    environnement: environment,
                    platform: 'web',
                    isWebview: isWebview(),
                    fs_color: product.fsColor,
                    color: product.color,
                    collection: product.collection,
                });
                return [2 /*return*/];
        }
    });
}); };
var error = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
    var instance, globalEData;
    var errorType = _b.errorType;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4 /*yield*/, analytics.getInstance(segmentConfig)];
            case 1:
                instance = _c.sent();
                globalEData = getGlobalEData();
                instance.track('Error page', {
                    event_id: analytics.getUniqueEventId('errorPage'),
                    'error-type': errorType,
                    'page-name': document.title,
                    url: window.location.href,
                    site: site,
                    country: globalEData === null || globalEData === void 0 ? void 0 : globalEData.countryISO,
                    siteBrand: context.brandCode,
                    siteCurrency: context.currencyCode,
                    langue: context.locale,
                    environnement: environment,
                    platform: 'web',
                    'type-contenu': 'erreur',
                    navPath: navPath.join('||'),
                    label: errorType,
                    isWebview: isWebview(),
                    nonInteraction: 1,
                });
                return [2 /*return*/];
        }
    });
}); };
var popinGlobalE = function () { return __awaiter(void 0, void 0, void 0, function () {
    var instance, globalEData;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, analytics.getInstance(segmentConfig)];
            case 1:
                instance = _a.sent();
                globalEData = getGlobalEData();
                instance.track('Pop-in localisation', {
                    event_id: analytics.getUniqueEventId('popinLocalisation'),
                    'page-name': document.title,
                    site: site,
                    country: globalEData === null || globalEData === void 0 ? void 0 : globalEData.countryISO,
                    siteBrand: context.brandCode,
                    siteCurrency: context.currencyCode,
                    langue: context.locale,
                    environnement: environment,
                    platform: 'web',
                    isWebview: isWebview(),
                    nonInteraction: 1,
                });
                return [2 /*return*/];
        }
    });
}); };
var dynamicPopin = function (popinType) { return __awaiter(void 0, void 0, void 0, function () {
    var instance, globalEData;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, analytics.getInstance(segmentConfig)];
            case 1:
                instance = _a.sent();
                globalEData = getGlobalEData();
                instance.track('Pop-in contribuee', {
                    event_id: analytics.getUniqueEventId('popinContribuee'),
                    'page-name': document.title,
                    site: site,
                    country: globalEData === null || globalEData === void 0 ? void 0 : globalEData.countryISO,
                    siteBrand: context.brandCode,
                    siteCurrency: context.currencyCode,
                    langue: context.locale,
                    environnement: environment,
                    platform: 'web',
                    'type-contenu': popinType,
                    isWebview: isWebview(),
                    nonInteraction: 1,
                });
                return [2 /*return*/];
        }
    });
}); };
var popinWishlist = function (product) { return __awaiter(void 0, void 0, void 0, function () {
    var instance, globalEData;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, analytics.getInstance(segmentConfig)];
            case 1:
                instance = _a.sent();
                globalEData = getGlobalEData();
                instance.track('Pop-in Wishlist Viewed', {
                    event_id: analytics.getUniqueEventId('popinWishlistViewed'),
                    'type-contenu': getContentType(),
                    'page-name': document.title,
                    product_id: product.sizeUUID,
                    product_code: product.productID,
                    productUUID: product.productUUID,
                    sku: product.productEAN,
                    name: product.productName,
                    price: formatPrice(product.productPrice),
                    category: product.productCategory,
                    site: site,
                    country: globalEData === null || globalEData === void 0 ? void 0 : globalEData.countryISO,
                    siteBrand: context.brandCode,
                    siteCurrency: context.currencyCode,
                    langue: context.locale,
                    environnement: environment,
                    platform: 'web',
                    label: product.productName,
                    value: formatPrice(product.productPrice),
                    isWebview: isWebview(),
                    nonInteraction: 1,
                    fs_color: product.fsColor,
                    color: product.color,
                    collection: product.collection,
                });
                return [2 /*return*/];
        }
    });
}); };
var popinWishlistClosed = function (product) { return __awaiter(void 0, void 0, void 0, function () {
    var instance, globalEData;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, analytics.getInstance(segmentConfig)];
            case 1:
                instance = _a.sent();
                globalEData = getGlobalEData();
                instance.track('Pop-in Wishlist Closed', {
                    event_id: analytics.getUniqueEventId('popinWishlistClosed'),
                    'type-contenu': getContentType(),
                    'page-name': document.title,
                    product_id: product.sizeUUID,
                    product_code: product.productID,
                    productUUID: product.productUUID,
                    sku: product.productEAN,
                    name: product.productName,
                    country: globalEData === null || globalEData === void 0 ? void 0 : globalEData.countryISO,
                    price: formatPrice(product.productPrice),
                    category: product.productCategory,
                    site: site,
                    siteBrand: context.brandCode,
                    siteCurrency: context.currencyCode,
                    langue: context.locale,
                    environnement: environment,
                    platform: 'web',
                    label: product.productName,
                    value: formatPrice(product.productPrice),
                    isWebview: isWebview(),
                    fs_color: product.fsColor,
                    color: product.color,
                    collection: product.collection,
                });
                return [2 /*return*/];
        }
    });
}); };
var productClicked = function (product) { return __awaiter(void 0, void 0, void 0, function () {
    var instance, globalEData;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, analytics.getInstance(segmentConfig)];
            case 1:
                instance = _a.sent();
                globalEData = getGlobalEData();
                instance.track('Product Clicked', {
                    event_id: analytics.getUniqueEventId('productClicked'),
                    product_id: product.sizeUUID,
                    product_code: product.productID,
                    productUUID: product.productUUID,
                    sku: product.productEAN,
                    category: product.productCategory,
                    country: globalEData === null || globalEData === void 0 ? void 0 : globalEData.countryISO,
                    name: product.productName,
                    brand: product.productBrand.toLowerCase(),
                    variant: product.productVariant,
                    price: formatPrice(product.productPrice),
                    productStatus: product.productStatus,
                    site: site,
                    siteBrand: context.brandCode,
                    siteCurrency: context.currencyCode,
                    langue: context.locale,
                    environnement: environment,
                    platform: 'web',
                    url: product.productURL,
                    isWebview: isWebview(),
                    fs_color: product.fsColor,
                    color: product.color,
                    collection: product.collection,
                });
                return [2 /*return*/];
        }
    });
}); };
var productAddedToCart = function (product) { return __awaiter(void 0, void 0, void 0, function () {
    var instance, globalEData, _a, _b, _c, _d;
    var _e;
    var _f;
    return __generator(this, function (_g) {
        switch (_g.label) {
            case 0: return [4 /*yield*/, analytics.getInstance(segmentConfig)];
            case 1:
                instance = _g.sent();
                globalEData = getGlobalEData();
                _b = (_a = instance).track;
                _c = ['Product Added'];
                _e = {
                    event_id: analytics.getUniqueEventId('productAdded'),
                    product_id: product.sizeUUID,
                    product_code: product.productID,
                    productUUID: product.productUUID,
                    country: globalEData === null || globalEData === void 0 ? void 0 : globalEData.countryISO,
                    sku: product.productEAN,
                    category: product.productCategory,
                    name: product.productName,
                    brand: (_f = product.productBrand) === null || _f === void 0 ? void 0 : _f.toLowerCase(),
                    variant: product.productVariant,
                    price: formatPrice(product.productPrice),
                    quantity: 1,
                    productStatus: product.productStatus,
                    site: site,
                    siteBrand: context.brandCode,
                    siteCurrency: context.currencyCode,
                    currency: context.currencyCode,
                    langue: context.locale,
                    environnement: environment,
                    platform: 'web',
                    url: product.productURL,
                    'event-type': 'Product page', // @TODO Should be `Product page` or `Quick Shop` depending on the context
                    label: product.productName,
                    value: formatPrice(product.productPrice),
                    isWebview: isWebview(),
                    cart_id: Cookies.get('SezaneCartUuid'),
                    subscriber_key: subscriberKey,
                    fs_color: product.fsColor,
                    color: product.color,
                    collection: product.collection
                };
                _d = 'adresse-email';
                return [4 /*yield*/, getEmail()];
            case 2:
                _b.apply(_a, _c.concat([(_e[_d] = _g.sent(),
                        _e.fbp = Cookies.get('_fbp'),
                        _e.fbc = Cookies.get('_fbc'),
                        _e)]));
                return [2 /*return*/];
        }
    });
}); };
var productAddedWishlist = function (product) { return __awaiter(void 0, void 0, void 0, function () {
    var instance, globalEData, _a, _b, _c, _d;
    var _e;
    return __generator(this, function (_f) {
        switch (_f.label) {
            case 0: return [4 /*yield*/, analytics.getInstance(segmentConfig)];
            case 1:
                instance = _f.sent();
                globalEData = getGlobalEData();
                _b = (_a = instance).track;
                _c = ['Product Added to Wishlist'];
                _e = {
                    event_id: analytics.getUniqueEventId('productAddedWishlist'),
                    product_id: product.sizeUUID,
                    product_code: product.productID,
                    productUUID: product.productUUID,
                    name: product.productName,
                    price: formatPrice(product.productPrice),
                    category: product.productCategory,
                    site: site,
                    country: globalEData === null || globalEData === void 0 ? void 0 : globalEData.countryISO,
                    siteBrand: context.brandCode,
                    siteCurrency: context.currencyCode,
                    currency: context.currencyCode,
                    langue: context.locale,
                    environnement: environment,
                    platform: 'web',
                    sku: product.productEAN,
                    brand: product.productBrand.toLowerCase(),
                    variant: product.productVariant,
                    productStatus: product.productStatus,
                    label: product.productName,
                    value: formatPrice(product.productPrice),
                    url: product.productURL,
                    isWebview: isWebview(),
                    fs_color: product.fsColor,
                    color: product.color,
                    collection: product.collection
                };
                _d = 'adresse-email';
                return [4 /*yield*/, getEmail()];
            case 2:
                _b.apply(_a, _c.concat([(_e[_d] = _f.sent(),
                        _e.fbp = Cookies.get('_fbp'),
                        _e.fbc = Cookies.get('_fbc'),
                        _e)]));
                return [2 /*return*/];
        }
    });
}); };
var productRemovedWishlist = function (product) { return __awaiter(void 0, void 0, void 0, function () {
    var instance, globalEData;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, analytics.getInstance(segmentConfig)];
            case 1:
                instance = _a.sent();
                globalEData = getGlobalEData();
                instance.track('Product Removed from Wishlist', {
                    event_id: analytics.getUniqueEventId('productRemovedWishlist'),
                    product_id: product.sizeUUID,
                    product_code: product.productID,
                    productUUID: product.productUUID,
                    name: product.productName,
                    price: formatPrice(product.productPrice),
                    category: product.productCategory,
                    site: site,
                    country: globalEData === null || globalEData === void 0 ? void 0 : globalEData.countryISO,
                    siteBrand: context.brandCode,
                    siteCurrency: context.currencyCode,
                    langue: context.locale,
                    environnement: environment,
                    platform: 'web',
                    sku: product.productEAN,
                    brand: product.productBrand.toLowerCase(),
                    variant: product.productVariant,
                    productStatus: product.productStatus,
                    label: product.productName,
                    value: formatPrice(product.productPrice),
                    url: product.productURL,
                    isWebview: isWebview(),
                    fs_color: product.fsColor,
                    color: product.color,
                    collection: product.collection,
                });
                return [2 /*return*/];
        }
    });
}); };
var quickshopViewed = function (product) { return __awaiter(void 0, void 0, void 0, function () {
    var instance, globalEData;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, analytics.getInstance(segmentConfig)];
            case 1:
                instance = _a.sent();
                globalEData = getGlobalEData();
                instance.track('Product Quick Shop Viewed', {
                    event_id: analytics.getUniqueEventId('productQuickShopViewed'),
                    product_id: product.sizeUUID,
                    product_code: product.productID,
                    sku: product.productEAN,
                    category: product.productCategory,
                    name: product.productName,
                    country: globalEData === null || globalEData === void 0 ? void 0 : globalEData.countryISO,
                    brand: product.productBrand.toLowerCase(),
                    variant: product.productVariant,
                    price: formatPrice(product.productPrice),
                    currency: context.currencyCode,
                    value: formatPrice(product.productPrice),
                    productUUID: product.productUUID,
                    site: site,
                    siteBrand: context.brandCode,
                    siteCurrency: context.currencyCode,
                    langue: context.locale,
                    environnement: environment,
                    platform: 'web',
                    label: product.productName,
                    url: product.productURL,
                    isWebview: isWebview(),
                    fs_color: product.fsColor,
                    color: product.color,
                    collection: product.collection,
                });
                return [2 /*return*/];
        }
    });
}); };
var quickshopClosed = function (product) { return __awaiter(void 0, void 0, void 0, function () {
    var instance, globalEData;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, analytics.getInstance(segmentConfig)];
            case 1:
                instance = _a.sent();
                globalEData = getGlobalEData();
                instance.track('Product Quick Shop Closed', {
                    event_id: analytics.getUniqueEventId('productQuickShopClosed'),
                    product_id: product.sizeUUID,
                    product_code: product.productID,
                    sku: product.productEAN,
                    category: product.productCategory,
                    name: product.productName,
                    country: globalEData === null || globalEData === void 0 ? void 0 : globalEData.countryISO,
                    brand: product.productBrand.toLowerCase(),
                    variant: product.productVariant,
                    price: formatPrice(product.productPrice),
                    currency: context.currencyCode,
                    value: formatPrice(product.productPrice),
                    productUUID: product.productUUID,
                    site: site,
                    siteBrand: context.brandCode,
                    siteCurrency: context.currencyCode,
                    langue: context.locale,
                    environnement: environment,
                    platform: 'web',
                    label: product.productName,
                    url: product.productURL,
                    isWebview: isWebview(),
                    fs_color: product.fsColor,
                    color: product.color,
                    collection: product.collection,
                });
                return [2 /*return*/];
        }
    });
}); };
var newsletterSubscription = function () { return __awaiter(void 0, void 0, void 0, function () {
    var instance, globalEData, _a, _b, _c, _d;
    var _e;
    return __generator(this, function (_f) {
        switch (_f.label) {
            case 0: return [4 /*yield*/, analytics.getInstance(segmentConfig)];
            case 1:
                instance = _f.sent();
                globalEData = getGlobalEData();
                _b = (_a = instance).track;
                _c = ['Newsletter_Subscription'];
                _e = {
                    event_id: analytics.getUniqueEventId('newsletterSubscription'),
                    newsletterSubscriptionLocation: 'Footer',
                    site: site,
                    country: globalEData === null || globalEData === void 0 ? void 0 : globalEData.countryISO,
                    siteBrand: context.brandCode,
                    siteCurrency: context.currencyCode,
                    langue: context.locale,
                    environnement: environment,
                    label: 'Footer',
                    platform: 'web',
                    isWebview: isWebview(),
                    subscriber_key: subscriberKey
                };
                _d = 'adresse-email';
                return [4 /*yield*/, getEmail()];
            case 2:
                _b.apply(_a, _c.concat([(_e[_d] = _f.sent(),
                        _e.fbp = Cookies.get('_fbp'),
                        _e.fbc = Cookies.get('_fbc'),
                        _e)]));
                return [2 /*return*/];
        }
    });
}); };
var productAlert = function (product) { return __awaiter(void 0, void 0, void 0, function () {
    var instance, globalEData;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, analytics.getInstance(segmentConfig)];
            case 1:
                instance = _a.sent();
                globalEData = getGlobalEData();
                instance.track('Product Alert', {
                    event_id: analytics.getUniqueEventId('productAlert'),
                    product_id: product.sizeUUID,
                    product_code: product.productID,
                    productUUID: product.productUUID,
                    name: product.productName,
                    price: formatPrice(product.productPrice),
                    category: product.productCategory,
                    site: site,
                    country: globalEData === null || globalEData === void 0 ? void 0 : globalEData.countryISO,
                    siteBrand: context.brandCode,
                    siteCurrency: context.currencyCode,
                    langue: context.locale,
                    environnement: environment,
                    platform: 'web',
                    sku: product.productEAN,
                    brand: product.productBrand.toLowerCase(),
                    productStatus: product.productStatus,
                    label: product.productName,
                    value: formatPrice(product.productPrice),
                    url: product.productURL,
                    isWebview: isWebview(),
                    fs_color: product.fsColor,
                    color: product.color,
                    collection: product.collection,
                });
                return [2 /*return*/];
        }
    });
}); };
var sizeGuide = function (product) { return __awaiter(void 0, void 0, void 0, function () {
    var instance, globalEData;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, analytics.getInstance(segmentConfig)];
            case 1:
                instance = _a.sent();
                globalEData = getGlobalEData();
                instance.track('Click size guide', {
                    event_id: analytics.getUniqueEventId('clickSizeGuide'),
                    product_id: product.sizeUUID,
                    product_code: product.productID,
                    name: product.productName,
                    price: formatPrice(product.productPrice),
                    category: product.productCategory,
                    site: site,
                    country: globalEData === null || globalEData === void 0 ? void 0 : globalEData.countryISO,
                    siteBrand: context.brandCode,
                    siteCurrency: context.currencyCode,
                    langue: context.locale,
                    environnement: environment,
                    platform: 'web',
                    sku: product.productEAN,
                    productStatus: product.productStatus,
                    label: product.productName,
                    value: formatPrice(product.productPrice),
                    isWebview: isWebview(),
                    fs_color: product.fsColor,
                    color: product.color,
                    collection: product.collection,
                });
                return [2 /*return*/];
        }
    });
}); };
var productTabDetail = function (product) { return __awaiter(void 0, void 0, void 0, function () {
    var instance, globalEData;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, analytics.getInstance(segmentConfig)];
            case 1:
                instance = _a.sent();
                globalEData = getGlobalEData();
                instance.track('Click details', {
                    event_id: analytics.getUniqueEventId('clickProductDetails'),
                    product_id: product.sizeUUID,
                    product_code: product.productID,
                    productUUID: product.productUUID,
                    name: product.productName,
                    price: formatPrice(product.productPrice),
                    category: product.productCategory,
                    site: site,
                    country: globalEData === null || globalEData === void 0 ? void 0 : globalEData.countryISO,
                    siteBrand: context.brandCode,
                    siteCurrency: context.currencyCode,
                    langue: context.locale,
                    environnement: environment,
                    platform: 'web',
                    sku: product.productEAN,
                    productStatus: product.productStatus,
                    label: product.productName,
                    value: formatPrice(product.productPrice),
                    isWebview: isWebview(),
                    fs_color: product.fsColor,
                    color: product.color,
                    collection: product.collection,
                });
                return [2 /*return*/];
        }
    });
}); };
var productTabWorkshop = function () { return __awaiter(void 0, void 0, void 0, function () {
    var instance, globalEData;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, analytics.getInstance(segmentConfig)];
            case 1:
                instance = _a.sent();
                globalEData = getGlobalEData();
                instance.track('Click workshops', {
                    event_id: analytics.getUniqueEventId('clickWorkshops'),
                    // atelier: $atelier,
                    // label: $atelier,
                    site: site,
                    siteBrand: context.brandCode,
                    country: globalEData === null || globalEData === void 0 ? void 0 : globalEData.countryISO,
                    siteCurrency: context.currencyCode,
                    langue: context.locale,
                    environnement: environment,
                    platform: 'web',
                    isWebview: isWebview(),
                });
                return [2 /*return*/];
        }
    });
}); };
var giftCardAdded = function (formData) { return __awaiter(void 0, void 0, void 0, function () {
    var instance, globalEData, _a, _b, _c, _d;
    var _e;
    return __generator(this, function (_f) {
        switch (_f.label) {
            case 0: return [4 /*yield*/, analytics.getInstance(segmentConfig)];
            case 1:
                instance = _f.sent();
                globalEData = getGlobalEData();
                _b = (_a = instance).track;
                _c = ['Gift Card Added'];
                _e = {
                    event_id: analytics.getUniqueEventId('giftCardAdded'),
                    'giftcard-amount': formData.amount,
                    value: formData.amount,
                    site: site,
                    country: globalEData === null || globalEData === void 0 ? void 0 : globalEData.countryISO,
                    siteBrand: context.brandCode,
                    siteCurrency: context.currencyCode,
                    langue: context.locale,
                    environnement: environment,
                    platform: 'web'
                };
                _d = 'adresse-email';
                return [4 /*yield*/, getEmail()];
            case 2:
                _e[_d] = _f.sent();
                return [4 /*yield*/, analytics.getSha256(formData.recipient)];
            case 3:
                _b.apply(_a, _c.concat([(_e.recipient = _f.sent(),
                        _e.isWebview = isWebview(),
                        _e)]));
                return [2 /*return*/];
        }
    });
}); };
var searchClick = function () { return __awaiter(void 0, void 0, void 0, function () {
    var instance, globalEData;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, analytics.getInstance(segmentConfig)];
            case 1:
                instance = _a.sent();
                globalEData = getGlobalEData();
                instance.track('Search click', {
                    event_id: analytics.getUniqueEventId('searchClick'),
                    site: site,
                    siteBrand: context.brandCode,
                    country: globalEData === null || globalEData === void 0 ? void 0 : globalEData.countryISO,
                    siteCurrency: context.currencyCode,
                    langue: context.locale,
                    environnement: environment,
                    platform: 'web',
                    isWebview: isWebview(),
                });
                return [2 /*return*/];
        }
    });
}); };
var cookiePopin = function () { return __awaiter(void 0, void 0, void 0, function () {
    var instance, globalEData;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, analytics.getInstance(segmentConfig)];
            case 1:
                instance = _a.sent();
                globalEData = getGlobalEData();
                instance.track('Pop-in cookies', {
                    event_id: analytics.getUniqueEventId('popinCookies'),
                    site: site,
                    country: globalEData === null || globalEData === void 0 ? void 0 : globalEData.countryISO,
                    siteBrand: context.brandCode,
                    siteCurrency: context.currencyCode,
                    langue: context.locale,
                    environnement: environment,
                    platform: 'web',
                    isWebview: isWebview(),
                    nonInteraction: 1,
                });
                return [2 /*return*/];
        }
    });
}); };
var changeCountry = function (country) { return __awaiter(void 0, void 0, void 0, function () {
    var instance;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, analytics.getInstance(segmentConfig)];
            case 1:
                instance = _a.sent();
                instance.track('Change_Country', {
                    event_id: analytics.getUniqueEventId('changeCountry'),
                    location: country,
                    country: country,
                    label: country,
                    site: site,
                    siteBrand: context.brandCode,
                    siteCurrency: context.currencyCode,
                    langue: context.locale,
                    environnement: environment,
                    platform: 'web',
                    isWebview: isWebview(),
                });
                return [2 /*return*/];
        }
    });
}); };
document.addEventListener('DOMContentLoaded', function () {
    setTimeout(function () {
        if (document.querySelector('#onetrust-banner-sdk')) {
            cookiePopin();
        }
    }, 1000);
    var hasSwitchedCountry = Cookies.get('switch_country');
    if (hasSwitchedCountry) {
        var globalEData = getGlobalEData();
        if (globalEData) {
            changeCountry(globalEData === null || globalEData === void 0 ? void 0 : globalEData.countryISO);
            Cookies.remove('switch_country');
        }
    }
});
// Public API
var API = {
    setContext: setContext,
    productsSearched: productsSearched,
    productListViewed: productListViewed,
    addProductInPage: addProductInPage,
    productViewed: productViewed,
    pageLoad: pageLoad,
    productCustomisationViewed: productCustomisationViewed,
    productWithoutCustomisation: productWithoutCustomisation,
    productWithCustomisation: productWithCustomisation,
    productCustomisationClosed: productCustomisationClosed,
    shopTheLook: shopTheLook,
    error: error,
    popinGlobalE: popinGlobalE,
    popinWishlist: popinWishlist,
    popinWishlistClosed: popinWishlistClosed,
    productClicked: productClicked,
    productAddedToCart: productAddedToCart,
    productAddedWishlist: productAddedWishlist,
    productRemovedWishlist: productRemovedWishlist,
    quickshopViewed: quickshopViewed,
    quickshopClosed: quickshopClosed,
    newsletterSubscription: newsletterSubscription,
    productAlert: productAlert,
    sizeGuide: sizeGuide,
    productTabDetail: productTabDetail,
    productTabWorkshop: productTabWorkshop,
    giftCardAdded: giftCardAdded,
    searchClick: searchClick,
    dynamicPopin: dynamicPopin,
    shopTheLookProductClicked: shopTheLookProductClicked,
};
window.analytics = API;
export default API;
